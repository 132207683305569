import axios from 'axios';
import common from './commonService';
import {StatisticsInfo, StatisticsEntry, PlateMatch, InsideEntry, InsideItem} from '../data/platesData';
import { MenuItem } from '@material-ui/core';
import { isArray, isNumber } from '@material-ui/data-grid';
import { StatusPanelComponent } from 'ag-grid-community/dist/lib/components/framework/componentTypes';
import { DraftsSharp, MarkunreadSharp } from '@material-ui/icons';
import plate from '../components/inside/editors/plate';



/**
 * Statistics generator (datasets)
 */
export class PlatesStatistics {


  constructor() {
    this.manager();
  }

  start = (datasetId: string) => {
    common.plates.statisticsPendingId = datasetId;
  }

  stop = () => {
    common.plates.statisticsPendingId = '';
    const state = common.plates.statisticsState;
    if (state === 'running')
      common.plates.statisticsPendingAbort = true;
  }

  /**
   * manager (handles switching between datasets)
   */
  manager = () => {
    try {
      setInterval(() => {
        const state = common.plates.statisticsState; 
        const pendingId = common.plates.statisticsPendingId;
        switch(state) {
          case "none":
            if (pendingId) {
               this.start2(pendingId);
               common.plates.statisticsPendingId = '';
            }
            break;
  
            case "running":
              if (pendingId) {
                common.plates.statisticsPendingAbort = true;
              }
              break;
        }
      }, 200);
    } catch (ex) {
      console.error('failed to manage statistics:', ex);
    }
  }

  /**
   * returns an empty plate structure
   * @returns 
   */
  getFreshPlate = () => {
    try {
      return { Annotations: []};
    } catch (ex) {
      console.error('failed to get fresh plate:');
      return null;
    }
  }

  /**
   * sanitizes native plate structure
   * @param plate 
   * @returns 
   */
  sanitizePlate = (plate:any) => {
    try {
      if (!plate)
        return;

      if (!plate.Annotations)
        plate.Annotations = [];

      if (!isArray(plate.Annotations))
        plate.Annotations = [plate.Annotations];

      plate.Annotations.forEach((a:any) => {
        if (!a.VehicleType)
          a.VehicleType = {status:'tagged', value: 0};

        if (!a.VehicleType.value)
          a.VehicleType.value = 0;

        if (!isNumber(a.VehicleType.value))
          a.VehicleType.value = parseInt(a.VehicleType.value);

        if (isNaN(a.VehicleType.value))
          a.VehicleType.value = 0;

        if (!a.PlateType)
          a.PlateType = {status: 'tagged', value: 0};

        if (!a.PlateType.value)
          a.PlateType.value = 0;

        if (!isNumber(a.PlateType.value))
          a.PlateType.value = parseInt(a.PlateType.value);

        if (isNaN(a.PlateType.value))
          a.PlateType.value = 0;
      });

     

    } catch (ex) {
      console.error('failed to sanitize plate:', ex);
    }
  }

  /**
   * resets the statistics
   */
  resetStats = () => {
    try {
      const ds = common.datasets;
      const stats = common.plates.stats;
      stats.totalEntries = 0;
      stats.taggingPie.forEach(p => p.value = 0);
      stats.vehiclesPie.forEach(p => p.value = 0);
      stats.platesPie.forEach(p => p.value = 0);
      stats.sequencePie.forEach(p => p.value = 0);
      stats.axlesPie.forEach(p => p.value = 0);
      stats.boxingPie.forEach(p => p.value = 0);
      stats.splitsPie.forEach(p => p.value = 0);
      stats.charsPie.forEach(p => p.value = 0);
      ds.selectedStatistic = '';

      // WTT-415, lights statistics
      stats.lights = [0,0,0,0,0];
      stats.totalLights = 0;

      stats.years = [];
      
      ds.taggingsStatistics = false;
      ds.axlesStatistics = false;
      ds.vehiclesStatistics = false;
      ds.colorsStatistics = false;
      ds.makersStatistics = false;
      ds.sequenceStatistics = false;
      ds.nationsStatistics = false;
      // ofer 22/06/2023 - patch
      ds.insideStatistics = false;
      ds.hazardStatistics = false;
      ds.lightsStatistics = false;
      ds.charsStatistics = false;

      ds.statTabAccuracy = false;
      ds.statTabOcr = false;
      ds.statTabBccm = false;
      ds.statTabSeq = false;
      ds.statTabAudit = false;
      ds.statTabVehicles = false;
      ds.statisticTypes = [];
      common.notify('StatisticsProgressChanged');
      } catch (ex) {
        console.log('failed to reset stats:', ex);
    }
    
  }

  updateStatisticTypes = () => {
    try {
      const types:string[] = [];
      const ds = common.datasets;
      if (ds.taggingsStatistics) types.push('Tagging', 'Accuracy', 'Plates', 'Splits', 'BCCM');
      if (ds.sequenceStatistics) types.push('Sequences', 'Audit');
      if (ds.vehiclesStatistics) types.push('Vehicles');
      if (ds.axlesStatistics) types.push('Axles');
      if (ds.makersStatistics) types.push('Makers');
      if (ds.colorsStatistics) types.push('Colors');
      if (ds.nationsStatistics) types.push('Nations');
      if (ds.yearsStatistics) types.push('Years');
      if (ds.insideStatistics) types.push('Inside seats', 'Inside features');
      if (ds.hazardStatistics) types.push('Hazards');
      if (ds.lightsStatistics) types.push('Lights');
      if (ds.charsStatistics) types.push('Chars');
      // WTT-403
      types.sort((a,b) => a.localeCompare(b));
      ds.statisticTypes = types;
      // try to maintain selection, otherwise select first in list
      if (ds.prevSelectedStatistics && types.includes(ds.prevSelectedStatistics)) {
        ds.selectedStatistic = ds.prevSelectedStatistics;
      } else {
        ds.selectedStatistic = types[0];
        ds.prevSelectedStatistics = types[0];
      }
    } catch (ex) {
      console.error('failed to update statistic types:', ex);
    }
  }

  /**
   * converts vehicle type to vehicle type index
   * @param vehicleClass 
   * @returns 
   */
  getAxlesVehicleType = (vehicleClass: string): number => {
    try {

      // this.vehicleClass = [["CAR", "CAR"], ["MOTORBIKE", "MOTORBIKE"], ["BUS", "BUS"], 
      // ["HEAVY TRUCK", "HEAVY TRUCK"], ["LIGHT TRUCK", "LIGHT TRUCK"], ["VAN", "VAN"], ["NA", "NA"]];
      switch(vehicleClass) {
        case "NA": return 1;
        case "CAR": return 2;
        case "VAN": return 3;
        case "BUS": return 4;
        case "LIGHT TRUCK": return 5;
        case "HEAVY TRUCK": return 6;
        case "MOTORBIKE": return 7;
      }
      return 0;
    } catch (ex) {
      console.error('failed to get vehicle type:', ex);
      return 0;
    }
  }

  /**
   * determines wether an axles counter sequence is valid
   * @param seq 
   * @returns 
   */
  isSequenceValid = (seq: any): boolean => {
    try {
      if (!seq)
        return false;

      const val = '';
      const errors: string[] = [];
      if (seq.vehicleClass === '') 
        return false;
     

      if (!seq.vehicleCat) 
        return false;

      if (seq.weather === '')
        return false;

      if (seq.axleCount < 2)
        return false;

      if (seq.trailer && seq.trailerAxleCount < 1)
        return false;

      if (seq.axleCount - seq.trailerAxleCount < 2)
        return false;

      if (!seq.vehicleOcclusion || seq.vehicleOcclusion === 'undefined')
        return false;

      return true;

    } catch (ex) {
      console.error('failed to validate current sequence:', ex);
      return false;
    }
  }

  /**
   * converts status to status index
   * @param sequence 
   * @returns 
   */
  getAxlesStatusIndex = (sequence: any): number => {
    try {
      if (!sequence)
        return 0;

      if (sequence.invalid || sequence.anomaly)
        return 2;
      
      return  this.isSequenceValid(sequence) ? 1 : 0;
    } catch (ex) {
      console.error('failed to get axles status index:', ex);
      return 0;
    }
  }

  /**
   * 
   * @param sequence 
   * @returns 
   */
  getAxlesAuditIndex = (sequence: any): number => {
    try {
      const audit = sequence?.human?.auditStatus || '';
      if (audit === 'passed')
        return 1;

      if (audit === 'failed')
        return 2;

      return 0;
    } catch (ex) {
      console.error('failed to get axles status index:', ex);
      return 0;
    }
  }

  

  /**
   * percentage value helper
   * @param n 
   * @param total 
   * @returns 
   */
  getPercentage = (n: number, total: number): number => {
    try {
      if (total === 0)
        return 0;

      return Math.round(100 * n / total);
    } catch (ex) {
      console.error('failed to get percentage:', ex);
      return 0;
    }
  }

  /**
   * async axles statistics generator
   * @param info 
   * @param sequences 
   * @returns 
   */
  startAxlesStatistics = async (info: StatisticsInfo, sequences: string[]) => {
    try {
      info.axles = true;
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      info.totalEntries = sequences.length;
      info.totalTags = 0;
      info.axles = true;
      const axleCounts = [0,0,0,0,0,0,0,0,0,0,0,0];
      const vehicleTypes = [0,0,0,0,0,0,0,0];
      const status = [0,0,0];
      const audits = [0,0,0];
       for (let i = 0; i < sequences.length; i++) {

        if (common.plates.statisticsPendingAbort) {
          common.plates.statisticsState  = 'none';
          common.plates.statisticsPercentage = 0;
          common.plates.statisticsPendingAbort = false;
          return;
        }
        const id = sequences[i];
        const url = `${serverUrl}/sequence/${id}`;
        const reply = await axios.get(url, {headers: {'Authorization': common.loginToken}});
        const seq = reply.data.sequence.human?.axle;
        const st = this.getAxlesStatusIndex(seq);
        const audit = this.getAxlesAuditIndex(reply.data?.sequence);
     

        audits[audit]++;
        status[st]++;
        // only tagged
        if (st === 1) {
          vehicleTypes[this.getAxlesVehicleType(seq.vehicleClass)]++;
          axleCounts[seq.axleCount]++;
          info.totalTags++;
        }

        common.plates.statisticsPercentage = Math.round(100 * i/ info.totalEntries);
        if (i % 10 === 0)
          common.notify("StatisticsProgressChanged");
      }

   
      info.axlesCounts = axleCounts;
      for (let i = 0; i < axleCounts.length; i++) {
        const ap = info.axlesPie[i];
        if (ap) {
          ap.value = axleCounts[i];
          ap.percentage = this.getPercentage(axleCounts[i], info.totalTags);
          ap.title = `${ap.value} (${ap.percentage}%)`;
        }
      }
      for (let i=0; i < vehicleTypes.length; i++)
        info.vehiclesPie[i].value = vehicleTypes[i];

        info.vehiclesPie.forEach(vp => {
          vp.percentage = this.getPercentage(vp.value, info.totalTags);
          vp.title = `${vp.name} - ${vp.value} (${vp.percentage}%)`;
        });

      for (let i = 0; i < status.length; i++) {
        const sp = info.sequencePie[i];
        sp.value = status[i];
        sp.percentage = this.getPercentage(status[i], info.totalEntries);
        sp.title = `${sp.name} - ${sp.value} (${sp.percentage}%)`;
      }

      // WTT-289 statistica sul audit
      for (let i = 0; i < audits.length; i++) {
        const sp = info.auditPie[i];
        sp.value = audits[i];
        sp.percentage = this.getPercentage(audits[i], info.totalEntries);
        sp.title = `${sp.name} - ${sp.value} (${sp.percentage}%)`;
      }

    
      common.plates.stats = info;
    } catch (ex) {
      common.relogIfTokenExpired(ex);
      console.error('failed to start axles statistics:', ex);
    }
    finally {
      common.plates.statisticsPercentage = 100;
      common.plates.statisticsState = 'none'; 
      // common.datasets.statisticTypes = ["Taggings", "Axles", "Vehicles", "Accuracy"];
      common.datasets.axlesStatistics = info.totalEntries > 0;
      common.datasets.vehiclesStatistics = info.totalTags > 0;
      common.datasets.sequenceStatistics = info.totalEntries > 0;
      common.datasets.yearsStatistics = false;
      common.datasets.statTabSeq = true;
      common.datasets.statTabAudit = true;
      


      this.updateStatisticTypes();
      common.notify("StatisticsProgressChanged");
    }
  }

  // WTT-176
  /**
   * determine wether a dataset is axles or ocr
   * @param data 
   * @returns 
   */
  datasetIsAxles = (data: any): boolean => {
    try {
      const ocrs = data?.images?.length || 0;
      const sequences = data?.sequences?.length || 0;
      const axles = ocrs === 0 && sequences > 0;
      return axles;
    } catch (ex) {
      console.error('failed on datasetIsAxles:', ex);
      return false;
    }
  }

  /**
   * runs a statistics cycle
   * @param datasetId 
   * @returns 
   */
  start2 = async (datasetId: string) => {
    try {
      common.plates.statisticsState = 'running'; 
      common.notify("StatisticsProgressChanged");
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const url = `${serverUrl}/dataset/${datasetId}`;
      // wtt-140
      // common.plates.scanPolicy.quitRequest();
      const data = await axios.get(url, {headers: {'Authorization': common.loginToken}});
   

      var images = data.data.images;
      // reset current data, blank panel while accessing data
      this.resetStats();
      const info = new StatisticsInfo();
      info.totalTagged = 0;
      info.totalProblematic = 0;
      info.totalNotTagged = 0;
      info.totalPretagged = 0;
      info.totalBoxedVehicles = 0;
      info.totalPlateMatched = 0;
      info.totalPlateNoPlate = 0;
      info.totalPlateNotMatched = 0;
      info.totalPlateNoAnnotations = 0;
      info.entries = [];
      info.axles = this.datasetIsAxles(data.data);
      info.vehiclesPie = common.plates.stats.vehiclesPie;
      info.platesPie = common.plates.stats.platesPie;
      info.boxingPie = common.plates.stats.boxingPie;
      info.splitsPie = common.plates.stats.splitsPie;
      info.charsPie = common.plates.stats.charsPie;
      info.totalChars = 0;
      info.totalNoChars = 0;
      
      info.totalEntries = images?.length;
      const ds = common.plates.datasets.find(ds => ds.id === datasetId);
      if (!ds) throw new Error('failed to find ds for statistics');
      ds.statistics = info;
      common.plates.statisticsPercentage = 0;
      common.notify("StatisticsProgressChanged");
      common.notify("StatisticsStateChanged");

      if (info.axles)
        return this.startAxlesStatistics(info, data.data.sequences);
      
      for (let i = 0; i < images.length; i++) {
        if (common.plates.statisticsPendingAbort) {
          common.plates.statisticsState  = 'none';
          common.plates.statisticsPercentage = 0;
          common.plates.statisticsPendingAbort = false;
          return;
        }
        const id = images[i];
        const url2 = `${serverUrl}/items/${id}`;
        const details = await axios.get(url2, {headers: {'Authorization': common.loginToken}});
        const plates = details.data?.image_library?.human?.plates || this.getFreshPlate();
        const bccm = details.data?.image_library?.human?.bccm;
        const vehicles = details.data?.image_library?.human?.vehicle;
        const insides = details.data?.image_library?.human?.inside_inspection?.Annotations || [];
        const header = details.data?.image_library?.header;
        const nativeInfo = details.data?.image_library?.info;
        const hazards = details.data?.image_library?.human?.hazardous?.Annotations || [];
        const lights = details.data?.image_library?.human?.trafficlight?.Annotations || [];       
        if (plates) {
          this.sanitizePlate(plates);
          const stat = this.getStatistics(plates, bccm, vehicles, insides, lights, hazards, header, nativeInfo, id);
          info.entries.push(stat);
        }
       
        common.plates.statisticsPercentage = Math.round(100 * i/ images.length);
        if (i % 10 === 0)
          common.notify("StatisticsProgressChanged");
      }


      common.plates.statisticsPercentage = 100;
      common.notify("StatisticsProgressChanged");
      info.totalEntries = info.entries.length;
      for (let i = 0; i < info.vehiclesPie.length;i++) {
        info.vehiclesPie[i].value = 0;
      }
      for (let i = 0; i < info.platesPie.length; i++) {
        info.platesPie[i].value = 0;
      }
      info.entries.forEach(e => {
        if (e.tags) info.totalTagged++;
        if (e.pretags) info.totalPretagged++;
        if (e.problematic) info.totalProblematic++;
        if (e.vehicleBoxing) info.totalBoxedVehicles++;

        info.totalInsides += e.inside.total;
        info.totalBelts += e.inside.belts;
        info.totalPassengers += e.inside.totalPassengers;
        info.totalPhones += e.inside.phones;
        if (e.inside.total > 0)
          info.insideTagged++; 
        
        for(let i  = 0; i < info.totalDriverPositions.length; i++)
          info.totalDriverPositions[i] += e.inside.driverPositions[i];

        for(let i  = 0; i < info.totalOccupations.length; i++)
          info.totalOccupations[i] += e.inside.occupation[i];
    
        if (e.headerPlateMatched === PlateMatch.Match) info.totalPlateMatched++;
        if (e.headerPlateMatched === PlateMatch.NoMatch) info.totalPlateNotMatched++;
        if (e.headerPlateMatched === PlateMatch.NoAnnotations) info.totalPlateNoAnnotations++;
        if (e.headerPlateMatched === PlateMatch.NoPlate) info.totalPlateNoPlate++;




        // safeguard for invalid vehicleType values
        e.vehicleTypes.forEach(vt => {
          // WTT-403 - use code to find the slice
          const slice = info.vehiclesPie.find(v => v.code === vt);
          if (slice)
            slice.value++;

            // if(info.vehiclesPie[vt])
            // info.vehiclesPie[vt].value++
        });

        // safeguard for invalid plateType values
        e.plateTypes.forEach(pt => {
           // WTT-403 - use code to find the slice
           const slice = info.platesPie.find(p => p.code === pt);
           if (slice)
             slice.value++;

          // if (info.platesPie[pt])
          //   info.platesPie[pt].value++;

        });
      
        info.totalTags += e.vehicleTypes.length;

        // WTT-389
        switch(e.split) {
          case 'train': info.splits[0]++; break;
          case 'val': info.splits[1]++; break;
          case 'test': info.splits[2]++; break;
          default: info.splits[3]++; break;
        }

        // WTT-421
        info.totalChars += e.chars;

        // WTT-414 hazards
        for (const cls in e.hazards) {
          const entry = info.hazards.find(h => h.name === cls);
          if (entry) {
            entry.count += e.hazards[cls];
          } else {
            info.hazards.push({name: cls, count: e.hazards[cls], percentage: 0, title:''});
          }
        }

      });

      info.vehiclesPie.forEach(vp => {
        vp.percentage = info.totalTags > 0 ? Math.round(100 * vp.value / info.totalTags) : 0;
      });

      info.platesPie.forEach(pp =>  {
        pp.percentage = info.totalTags > 0 ? Math.round(100 * pp.value / info.totalTags) : 0;
      });

      info.insideTaggedPercentage = this.getPercentage(info.insideTagged, info.totalEntries);

      info.totalNotTagged = info.totalEntries - info.totalTagged - info.totalPretagged - info.totalProblematic;
      const pie = common.plates.stats.taggingPie;
      pie[0].value = info.totalNotTagged;
      pie[1].value = info.totalTagged;
      pie[2].value = info.totalPretagged;
      pie[3].value = info.totalProblematic;
      pie.forEach(p => p.percentage = info.totalEntries > 0 ? Math.round(100 * p.value / info.totalEntries) : 0)
      pie.forEach(p => p.title = `${p.name} ${p.value} (${p.percentage}%)`);
      common.plates.stats = info;

      const boxPie = common.plates.stats.boxingPie;
      info.totalNotBoxedVehicles = info.totalEntries - info.totalBoxedVehicles;
      boxPie[0].value = info.totalNotBoxedVehicles;
      boxPie[0].percentage = this.getPercentage(info.totalNotBoxedVehicles, info.totalEntries);
      boxPie[1].value = info.totalBoxedVehicles;
      boxPie[1].percentage = this.getPercentage(info.totalBoxedVehicles, info.totalEntries);
      boxPie.forEach(p => p.title = `${p.name} ${p.value} (${p.percentage}%)`);

      const charsPie = common.plates.stats.charsPie;
      info.totalNoChars = info.totalEntries - info.totalChars;
      charsPie[0].value = info.totalNoChars;
      charsPie[0].percentage = this.getPercentage(info.totalNoChars, info.totalEntries);
      charsPie[1].value = info.totalChars;
      charsPie[1].percentage = this.getPercentage(info.totalChars, info.totalEntries);
      charsPie.forEach(p => p.title = `${p.name} ${p.value} (${p.percentage}%)`);

      
      const accPie = common.plates.stats.accuracyPie;
      accPie[0].value = info.totalPlateNoPlate;
      accPie[0].percentage = this.getPercentage(info.totalPlateNoPlate, info.totalEntries);
      accPie[1].value = info.totalPlateNoAnnotations
      accPie[1].percentage = this.getPercentage(info.totalPlateNoAnnotations, info.totalEntries);
      accPie[2].value = info.totalPlateNotMatched;
      accPie[2].percentage = this.getPercentage(info.totalPlateNotMatched, info.totalEntries);
      accPie[3].value = info.totalPlateMatched;
      accPie[3].percentage = this.getPercentage(info.totalPlateMatched, info.totalEntries);
      accPie.forEach(p => p.title = `${p.name} ${p.value} (${p.percentage}%)`);

      const beltPie = common.plates.stats.beltPie;
      const noBelt = info.totalInsides - info.totalBelts;
      beltPie[0].value = noBelt;
      beltPie[0].percentage = this.getPercentage(noBelt, info.totalInsides);
      beltPie[0].title =  `${beltPie[0].value} (${beltPie[0].percentage}%)`;
      beltPie[1].value = info.totalBelts;
      beltPie[1].percentage = this.getPercentage(info.totalBelts, info.totalPassengers);
      beltPie[1].title =  `${beltPie[1].value} (${beltPie[1].percentage}%)`;
  
      const phonePie = common.plates.stats.phonePie;
      const noPhone = info.totalInsides - info.totalPhones;
      phonePie[0].value = noPhone;
      phonePie[0].percentage = this.getPercentage(noPhone, info.totalInsides);
      phonePie[0].title =  `${phonePie[0].value} (${phonePie[0].percentage}%)`;
      phonePie[1].value = info.totalPhones;
      phonePie[1].percentage = this.getPercentage(info.totalPhones, info.totalInsides);
      phonePie[1].title =  `${phonePie[1].value} (${phonePie[1].percentage}%)`;

      const occupationPie = common.plates.stats.occupationPie;
      let totalOccupations = 0;
      for (let i = 0; i < info.totalOccupations.length; i++)
        totalOccupations += info.totalOccupations[i];

      for (let i = 0; i < 7; i++) {
        occupationPie[i].value = info.totalOccupations[i];
        occupationPie[i].percentage = this.getPercentage(info.totalOccupations[i], totalOccupations);
        occupationPie[i].title =  `${occupationPie[i].name} - ${occupationPie[i].value} (${occupationPie[i].percentage}%)`;
      }

      // WTT-389
      const splitsPie = common.plates.stats.splitsPie;
      for (let i = 0; i < 4; i++) {
        splitsPie[i].value = info.splits[i];
        splitsPie[i].percentage = this.getPercentage(info.splits[i], info.totalEntries);
        splitsPie[i].title =    `${splitsPie[i].name} - ${splitsPie[i].value} (${splitsPie[i].percentage}%)`;
      };

      const filter = common.plates.filter;
      filter.vehicleTypes[0].count = info.totalTags;
      if (filter.vehicleTypes.length !== info.vehiclesPie.length + 1) throw('pie length error');
      for(let i = 0; i < info.vehiclesPie.length; i++)
        filter.vehicleTypes[i+1].count = info.vehiclesPie[i].value;
      
        info.vehiclesPie.forEach(p => {
          p.title = `${p.name} - ${p.value} (${p.percentage}%)`;
        });

      if (filter.plateTypes.length !== info.platesPie.length + 1) throw('pie length error');
      for (let i = 0; i < info.platesPie.length; i++)
        filter.plateTypes[i+1].count = info.platesPie[i].value;

      info.platesPie.forEach(p => {
        p.title = `${p.name} - ${p.value} (${p.percentage}%)`;
      });

   
        common.plates.stats.taggingPie = pie;
    
    
      this.generateBccmStatistics(info);
      this.generateNationStatistics(info);
      this.generateYearStatistics(info);
      this.getnerateHazardStatistics(info);
      this.generateLightsStatics(info);

      info.bccmPie.forEach(p => {
        p.percentage = this.getPercentage(p.value, info.totalEntries);
        p.title = `${p.name} - ${p.value} (${p.percentage}%)  `;
       });

       info.lightsPie.forEach(p => {
        p.percentage = this.getPercentage(p.value, info.totalLights);
        p.title = `${p.name} - ${p.value} (${p.percentage}%)  `;
       });


      common.datasets.taggingsStatistics = info.totalEntries > 0;
      common.datasets.colorsStatistics = info.totalBccmColors > 0;
      common.datasets.makersStatistics = info.totalBccmMakers > 0;
      common.datasets.vehiclesStatistics = info.totalTagged > 0;
      common.datasets.nationsStatistics = info.nations?.length > 0;
      common.datasets.yearsStatistics = info.years.length > 0;
      common.datasets.insideStatistics = info.totalInsides > 0;
      common.datasets.hazardStatistics = info.hazards.length > 0;
      common.datasets.lightsStatistics = info.totalLights > 0;
      common.datasets.charsStatistics = info.totalTags > 0;
      

      common.datasets.statTabBccm = info.totalBccmMakers > 0;
      common.datasets.statTabOcr = true;
      common.datasets.statTabAccuracy = true;
      common.datasets.statTabVehicles = info.totalBoxedVehicles > 0;
      common.datasets.statTabInside = info.totalInsides > 0;
      common.datasets.statTabCustom = info.nations?.length > 0;
      const dts = common.datasets;
      
      this.updateStatisticTypes();

      // WTT-345 - preserve tab selection
      setTimeout(() => {
        const tabNames = this.getTabNames();
        const index = Math.max(tabNames.indexOf(common.datasets.selectedTabName || ''),0);
        common.datasets.selectedTab = index;
        common.datasets.selectedTabName = tabNames[index];
        common.notify('StatisticsSelectedTabChanged');
      },100);
   
  
      common.plates.statisticsState = 'none';
      common.notify("StatisticsProgressChanged");
      common.notify('StatisticsCollected');

    } catch (ex) {
      common.relogIfTokenExpired(ex);
      console.error('failed to start statistics: ', ex);
      common.plates.statisticsState = 'none';
      common.notify("StatisticsProgressChanged");
    }
  }

      /**
   * this order has to be maintained
   */
       getTabNames = ():string[] => {
        try {
          const ds = common.datasets;
          const tabs:string[] = [];
          if (ds.statTabOcr) tabs.push('OCR');
          if (ds.statTabAccuracy) tabs.push('Accuracy');
          if (ds.statTabBccm) tabs.push('Bccm');
          if (ds.statTabSeq) tabs.push('Axles');
          if (ds.statTabAudit) tabs.push('Audit');
          if (ds.statTabVehicles) tabs.push('Vehicles');
          if (ds.statTabInside) tabs.push('Inside');
          if (ds.statTabCustom) tabs.push('Custom');
          return tabs;
        } catch (ex) {
          console.error('failed to get tab names:', ex);
          return [];
        }
  }
  

  /**
   * aggregate nations
   */
  generateNationStatistics = (info: StatisticsInfo) => {
    try {
      const dist: any = {};
      let total = 0;
      info.entries.forEach(e => {
        e.nations.forEach(n => {
          if (!dist[n])
            dist[n] = 0;

          dist[n]++;
          total++;
        })
      });
      info.nations = Object.keys(dist).map((k:string) => ({title: '', nation:k, count: dist[k], percentage: Math.round(100 * dist[k] / total) }))
      info.nations.forEach(n => n.title = `${n.nation} ${n.count} (${n.percentage}%)`);
      info.nations.sort((a,b) =>b.count - a.count);
    } catch (ex) {
      console.error('failed to generate nation statistics')
    }
  }

  /**
   * generate year distribution statistics WTT-401
   * @param info 
   */
  generateYearStatistics = (info: StatisticsInfo) => {
    try {
      const dist: any = {};
      let total = 0;
      info.entries.forEach(e => {
        if (!dist[e.year])
          dist[e.year] = 0;

        dist[e.year]++;
        total++;
      });

      info.years = Object.keys(dist).map((k:string) => ({title: '', year:k, count: dist[k], percentage: Math.round(100 * dist[k] / total) }))
      info.years.forEach(n => n.title = `${n.year === '0' ? 'Unknown' : n.year} ${n.count} (${n.percentage}%)`);
      info.years.sort((a,b) =>parseInt(b.year) - parseInt(a.year));

    } catch (ex) {
      console.error('failed to generate nation statistics')
    }
  }

  getnerateHazardStatistics = (info: StatisticsInfo) => {
    try {
      var total = 0;
      info.hazards.forEach(h => total += h.count);
      info.hazards.forEach(h => h.percentage = Math.round (100 * h.count / total));
      info.hazards.forEach(h => h.title = `class ${h.name} - ${h.count} (${h.percentage}%)`);
      

    } catch (ex) {
      console.error('failed to generate hazardStatistics:', ex);
    }
  }

  /**
   * generate statistics for the bccm part
   * @param info 
   */
  generateBccmStatistics = (info: StatisticsInfo) => {
    try {
      const colorKeys:any = { GRAY: 'gray', WHITE: 'whitesmoke', BLACK: 'black', UNK: 'cyan', ORANGE: 'orange', RED: 'red', 
      GREEN: 'green', BROWN: 'brown', BLUE: 'blue', YELLOW: 'yellow'
    }
      const makers:any = {};
      const colors:any = {};
      const entries = info?.entries;
      const states: number[] = [0,0,0];
      entries.forEach((e:any) => {
        // correzione WTT-391 - no bccm section
        if (!e.bccm) states[0]++;
        if (e.bccm) {
          states[e.bccm.state]++;
          e.bccm.colors.forEach((c:string) => {
            colors[c] = colors[c] ?? 0;
            colors[c]++;
            info.totalBccmColors++;
          });

          e.bccm.makers.forEach((m:string) => {
            makers[m] = makers[m] ?? 0;
            makers[m]++;
            info.totalBccmMakers++;
          });
        }
      })
      const makersPie:any[] = [];
      for (const m in makers) {
        if (makers.hasOwnProperty(m)) {
          const slice = {name: m, title: m, value: makers[m], color:'gray', percentage: this.getPercentage(makers[m], info.totalBccmMakers) };
          makersPie.push(slice);
        }
      }
      info.makersPie = makersPie;
      

      makersPie.sort((a,b) => { return b.value - a.value});
      if (makersPie.length > 0) {
        const w = makersPie[0].percentage * 2;
        info.makersWidth = w;
      }

      makersPie.forEach(p => {p.title = `${p.name} - ${p.value}`; });
      const tens = [];
      let i = 0;
      while (i < makersPie.length) {
        const low = i;
        const high = Math.min(i + 15, makersPie.length);
        const t = makersPie.slice(low, high);
        tens.push(t);
        i = high;
      }

      info.makers10 = tens;
      
      const colorsPie: any[] = [];
      for (const c in colors) {
        if (colors.hasOwnProperty(c)) {
          const slice = {name: c, title: c, value: colors[c], color: colorKeys[c], percentage: this.getPercentage(colors[c], info.totalBccmColors) };
          colorsPie.push(slice);
        }
      }

      colorsPie.forEach(p => {p.title = `${p.name} - ${p.value} (${p.percentage})`; });
      info.colorsPie = colorsPie;

      for(let i = 0; i < 3; i++)
        info.bccmPie[i].value = states[i];

      console.log('done');
      

    } catch (ex) {
      console.error('failed to generate bccm statistics:', ex);
    }
  }

  /**
   * accumulate statistics on lights
   * @param info 
   */
  generateLightsStatics = (info:StatisticsInfo) => {
    try {
      const entries = info.entries ||  [];
      entries.forEach(e => {
        for(let i = 0; i < 5; i++) {
          info.lights[i] += e.lights[i];
        }
      });

      // prepare lights pie
      info.totalLights = 0;
      for (let i = 0; i < 5; i++) {
        info.totalLights += info.lights[i];
        info.lightsPie[i].value = info.lights[i];
      }

    } catch (ex) {
      console.error('failed to generate lights statics:', ex);
    }
  }

  getInt = (v:any):number => {
    try {
      return isNumber(v) ? v : parseInt(v);
    } catch (ex) {
      console.error('failed on getInt:', ex);
      return 0;
    }
  }

  getInsideStatistics = (insides:any) => {
    try {
      let occupied = 0;
      let drivers = 0;
      let belts = 0;
      let phones = 0;
      let occupation = [0,0,0,0,0,0,0];
      let totalPassengers = 0;
      // up to six passengers
      const driverPositions = [0,0];
      const len = isArray(insides) ? insides?.length : 0;
      for (let i  = 0; i < len; i++) {
        const inside = insides[i];

        occupied = inside.passengers.length;
        totalPassengers += occupied;

        occupation[occupied]++;

        for (let j = 0; j < inside.passengers.length; j++) {
          const passenger = inside.passengers[j];

          // driver, belt, fastened, phone, in_use
          const stat = this.getInsideArray(passenger) || [0,0,0,0,0];
          if (passenger.position === 'front_left')
            driverPositions[0] += stat[0];

          if (passenger.position === 'front_right')
            driverPositions[1] += stat[0];

          belts += stat[1];
          phones += stat[3];
        }
      }
      
      const total = insides?.length || 0;
      return {total, occupied,drivers, belts, phones, occupation, driverPositions, totalPassengers};

    } catch (ex) {
      console.error('failed to get inside statistics:', ex);
    }
  }

  getHazardStatistics = (hazards:any[]) => {
    try {
      const stats:any = {};
      for (const h of hazards) {
        if (!h.class) continue;
        if (stats[h.class] === undefined)
          stats[h.class] = 0;

        stats[h.class]++;
      }
      return stats;      
    } catch(ex) {
      console.error('failed to get hazards statistics:', ex);
      return {};
    }
  }

  /**
   * at least one annotation
   * @param ants 
   * @returns 
   */
  getCharsStatistics = (ants:any[]) => {
    try {
      const chars = ants.filter(a => !!a.CharsPoly);
      return chars.length > 0 ? 1 : 0;
    } catch(ex) {
      console.error('failed to get ');
      return 0;
    }
  } 

  /**
   * note - native item
   * @param item 
   * @returns 
   */
  getInsideArray = (item:any) => {
    try {
      if (!item) 
        return [0,0,0,0,0];

        return [item.driver  ? 1 : 0,
        item.belt.visible ? 1 : 0,
        item.belt.fastened ? 1 : 0,
        item.phone.visible ? 1 : 0,
        item.phone.in_use ? 1 : 0];
    } catch (ex) {
      console.error('failed to get inside array (statistics):', ex);
      return [0,0,0,0,0];
    }
  }

  getBccmStatistics = (bccm: any) => {
    try {
      const makers: string[] = [];
      const colors:string[] = [];
      let state: number = 0;
      
      let annotations = bccm?.Annotations;
      if (!annotations)
        return null;

      state = 1;

      // sanitize
      if (!isArray(annotations))
        annotations = [annotations];
  
      annotations.forEach((a:any) => {
        state = 2;
        const make = a?.car_make?.value;
        const color = a?.car_color?.value;
        if (make)
          makers.push(make);

        if (color)
          colors.push(color);
      });
      return {state, makers, colors};
    } catch (ex) {
      console.error('failed to get bccm statistics:', ex);
      return null;
    }
   
    
  } 

  getStatistics = (plates: any, bccm:any, vehicles: any, insides:any, lights:any[], hazards:any[], header:any, info:any, id: string): StatisticsEntry => {
    // 1. tagging statistics
    const e = this.getTaggingStatistics(plates, id);

    const oldy = vehicles?.length > 0;
    if (oldy) {
      console.error(`Vehicles without Annotations (old format) ${id}`);
    }

    // ofer 25/01/2022 - old and new vehicles format (until scripted)
    // ofer 12/10/2021 WTT-149 - introduce vehicles statistics
    e.vehicleBoxing = vehicles?.length > 0 || vehicles?.Annotations?.length > 0;

    try {

      let plateNumber = header?.S_PLATE;
      if (plateNumber)
      plateNumber = plateNumber.split('-')[0];
      const ants = plates?.Annotations || [];
      if (!plateNumber)
        e.headerPlateMatched = PlateMatch.NoPlate;
      else if (ants.length == 0)
        e.headerPlateMatched = PlateMatch.NoAnnotations;
      else 
        e.headerPlateMatched = PlateMatch.NoMatch;

      if (!plates?.Annotations)
        return e;

      // 2. vehicle statistics
      plates.Annotations.forEach((a:any) => {
        e.nations.push(this.getNationRegion(a));
        e.vehicleTypes.push(a.VehicleType.value);
        e.plateTypes.push(a.PlateType.value);
        if (plateNumber && a.PlateNumber?.value === plateNumber)
          e.headerPlateMatched = PlateMatch.Match;
      });
      e.bccm = this.getBccmStatistics(bccm);
      e.inside = this.getInsideStatistics(insides);
      e.hazards = this.getHazardStatistics(hazards);
      e.chars = this.getCharsStatistics(plates.Annotations);
      // WTT-389
      e.split = info?.split;
      // WTT-401 - vega or stark
      // e.year = this.getYearFromHeader(header);
      // WTT-418 - use info.header.year
      e.year = parseInt(info?.year) || 0;
      
    
      // WTT-415 - traffic lights statistics
      e.lights = this.getLightsStatistics(lights);

      return e;
    } catch (ex) {
      console.error('failed to get statistics:', ex);
      return  e;
    }
  }

  getLightsStatistics = (lights:any[]) => {
    try {
      const stat = [0,0,0,0,0];
      for (const l of lights) {
        const ons = (l?.lights || []).filter((ll:any) => ll.status === 'on');
        if (ons.length === 0) // all off
          stat[0]++;
        else if (ons.length > 1) // multiple on
          stat[4]++;
        else {
          switch(ons[0].type) {
            case "red": stat[1]++; break;
            case "yellow": stat[2]++; break;
            case "green": stat[3]++; break;
          }
        }
      }
      return stat;
    } catch (ex) {
      console.error('failed to get lights');
      return [0,0,0,0,0];
    }
  }

    /**
   * get year component from header date field
   * @param header 
   * @returns 
   */
    getYearFromHeader(header:any) {
      try {
        // vega or stark
        var date = header ? (header['S_DATE'] || header['TRANSIT.START_TIMESTAMP'])  : '';
        if (!date) return '0';
        const year = date.split('-')[0];
  
        return year;
      } catch (ex) {
        console.error('failed to get year from header:', ex);
        return '0';
      }
    }

  /**
   * return nation.region
   * @param a 
   * @returns 
   */
  getNationRegion = (a:any) => {
    try {
      const nation = a.Nation?.value || '';
      const region = a.Region?.value || '';
      if (nation && region)
        return `${nation}.${region}`;
      if (nation)
        return nation;

    } catch(ex) {
      console.error('failed to get nation region:', ex);
    }
  }

  getTaggingStatistics = (plates: any, id: string): StatisticsEntry => {
    const e = new StatisticsEntry();
    try {
      e.id = id;
      if (!plates)
        return e;

      e.data = true;
      e.problematic = plates.Problematic === true;
      // problematic overrides pretagged and tagged
      if (e.problematic)
        return e;

      // pretagged overrides tagged
      e.pretags = this.isPreTagged(plates.Annotations);
      if (e.pretags)
        return e;

        // WTT-392 - tagged only if there is a polygon
        // e.tags = plates?.Annotations?.length > 0;
        e.tags = this.strictlyTagged(plates?.Annotations);

      if (!e.tags)
        return e;
      
      return e;
    } catch (ex) {
      console.error('failed to get statistics:', ex);
      return e;
    }
  }

  /**
   * WTT-392 - tagged only under strict conditions (polygon)
   * NOTE: plate might be empty (when not readable)
   * @param annotations 
   * @returns 
   */
  strictlyTagged = (annotations: any[]): boolean => {
    try {
      if (!annotations || annotations.length === 0)
        return false;

      for (let i = 0; i < annotations.length; i++) {
        const ant = annotations[i];
        if (ant?.polyPlaceholder)
          return false;
          
        const poly = ant?.Poly?.value;
        if (!poly)
          return false;
        if (poly.startsWith('NaN'))
          return false;

      }
      return true;
    } catch (ex) {
      console.error('failed on strictlyTagged:', ex);
      return false;
    }
  }

  isPreTagged = (annotations: any[]): boolean => {
    try {
      if (!annotations)
        return false;

      const fields = ["PlateNumber","WhiteChars","Bilingual","PlateType","Nation","VehicleType",
      "ExtraType","Category","CharHeight","Poly","Rect","InternalPoly", "PlateType"];
      let preTagged = false;
      annotations.forEach(ant => {
          fields.forEach(f => {
            if(ant[f]?.status === 'pre-tagged')
              preTagged = true;
          });
        });
      return preTagged;
    } catch (ex) {
      console.error('failed on isPreTagged (1):', ex);
      return false;
    }
  }




}
